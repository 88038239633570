import { __assign } from "tslib";
import { SENSITIVE_STRING } from "@aws-sdk/smithy-client";
export var RecoveryOptionNameType;
(function (RecoveryOptionNameType) {
    RecoveryOptionNameType["ADMIN_ONLY"] = "admin_only";
    RecoveryOptionNameType["VERIFIED_EMAIL"] = "verified_email";
    RecoveryOptionNameType["VERIFIED_PHONE_NUMBER"] = "verified_phone_number";
})(RecoveryOptionNameType || (RecoveryOptionNameType = {}));
export var RecoveryOptionType;
(function (RecoveryOptionType) {
    RecoveryOptionType.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(RecoveryOptionType || (RecoveryOptionType = {}));
export var AccountRecoverySettingType;
(function (AccountRecoverySettingType) {
    AccountRecoverySettingType.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(AccountRecoverySettingType || (AccountRecoverySettingType = {}));
export var AccountTakeoverEventActionType;
(function (AccountTakeoverEventActionType) {
    AccountTakeoverEventActionType["BLOCK"] = "BLOCK";
    AccountTakeoverEventActionType["MFA_IF_CONFIGURED"] = "MFA_IF_CONFIGURED";
    AccountTakeoverEventActionType["MFA_REQUIRED"] = "MFA_REQUIRED";
    AccountTakeoverEventActionType["NO_ACTION"] = "NO_ACTION";
})(AccountTakeoverEventActionType || (AccountTakeoverEventActionType = {}));
export var AccountTakeoverActionType;
(function (AccountTakeoverActionType) {
    AccountTakeoverActionType.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(AccountTakeoverActionType || (AccountTakeoverActionType = {}));
export var AccountTakeoverActionsType;
(function (AccountTakeoverActionsType) {
    AccountTakeoverActionsType.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(AccountTakeoverActionsType || (AccountTakeoverActionsType = {}));
export var NotifyEmailType;
(function (NotifyEmailType) {
    NotifyEmailType.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(NotifyEmailType || (NotifyEmailType = {}));
export var NotifyConfigurationType;
(function (NotifyConfigurationType) {
    NotifyConfigurationType.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(NotifyConfigurationType || (NotifyConfigurationType = {}));
export var AccountTakeoverRiskConfigurationType;
(function (AccountTakeoverRiskConfigurationType) {
    AccountTakeoverRiskConfigurationType.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(AccountTakeoverRiskConfigurationType || (AccountTakeoverRiskConfigurationType = {}));
export var AttributeDataType;
(function (AttributeDataType) {
    AttributeDataType["BOOLEAN"] = "Boolean";
    AttributeDataType["DATETIME"] = "DateTime";
    AttributeDataType["NUMBER"] = "Number";
    AttributeDataType["STRING"] = "String";
})(AttributeDataType || (AttributeDataType = {}));
export var NumberAttributeConstraintsType;
(function (NumberAttributeConstraintsType) {
    NumberAttributeConstraintsType.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(NumberAttributeConstraintsType || (NumberAttributeConstraintsType = {}));
export var StringAttributeConstraintsType;
(function (StringAttributeConstraintsType) {
    StringAttributeConstraintsType.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(StringAttributeConstraintsType || (StringAttributeConstraintsType = {}));
export var SchemaAttributeType;
(function (SchemaAttributeType) {
    SchemaAttributeType.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(SchemaAttributeType || (SchemaAttributeType = {}));
export var AddCustomAttributesRequest;
(function (AddCustomAttributesRequest) {
    AddCustomAttributesRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(AddCustomAttributesRequest || (AddCustomAttributesRequest = {}));
export var AddCustomAttributesResponse;
(function (AddCustomAttributesResponse) {
    AddCustomAttributesResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(AddCustomAttributesResponse || (AddCustomAttributesResponse = {}));
export var InternalErrorException;
(function (InternalErrorException) {
    InternalErrorException.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(InternalErrorException || (InternalErrorException = {}));
export var InvalidParameterException;
(function (InvalidParameterException) {
    InvalidParameterException.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(InvalidParameterException || (InvalidParameterException = {}));
export var NotAuthorizedException;
(function (NotAuthorizedException) {
    NotAuthorizedException.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(NotAuthorizedException || (NotAuthorizedException = {}));
export var ResourceNotFoundException;
(function (ResourceNotFoundException) {
    ResourceNotFoundException.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(ResourceNotFoundException || (ResourceNotFoundException = {}));
export var TooManyRequestsException;
(function (TooManyRequestsException) {
    TooManyRequestsException.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(TooManyRequestsException || (TooManyRequestsException = {}));
export var UserImportInProgressException;
(function (UserImportInProgressException) {
    UserImportInProgressException.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(UserImportInProgressException || (UserImportInProgressException = {}));
export var AdminAddUserToGroupRequest;
(function (AdminAddUserToGroupRequest) {
    AdminAddUserToGroupRequest.filterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.Username && { Username: SENSITIVE_STRING }))); };
})(AdminAddUserToGroupRequest || (AdminAddUserToGroupRequest = {}));
export var UserNotFoundException;
(function (UserNotFoundException) {
    UserNotFoundException.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(UserNotFoundException || (UserNotFoundException = {}));
export var AdminConfirmSignUpRequest;
(function (AdminConfirmSignUpRequest) {
    AdminConfirmSignUpRequest.filterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.Username && { Username: SENSITIVE_STRING }))); };
})(AdminConfirmSignUpRequest || (AdminConfirmSignUpRequest = {}));
export var AdminConfirmSignUpResponse;
(function (AdminConfirmSignUpResponse) {
    AdminConfirmSignUpResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(AdminConfirmSignUpResponse || (AdminConfirmSignUpResponse = {}));
export var InvalidLambdaResponseException;
(function (InvalidLambdaResponseException) {
    InvalidLambdaResponseException.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(InvalidLambdaResponseException || (InvalidLambdaResponseException = {}));
export var LimitExceededException;
(function (LimitExceededException) {
    LimitExceededException.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(LimitExceededException || (LimitExceededException = {}));
export var TooManyFailedAttemptsException;
(function (TooManyFailedAttemptsException) {
    TooManyFailedAttemptsException.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(TooManyFailedAttemptsException || (TooManyFailedAttemptsException = {}));
export var UnexpectedLambdaException;
(function (UnexpectedLambdaException) {
    UnexpectedLambdaException.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(UnexpectedLambdaException || (UnexpectedLambdaException = {}));
export var UserLambdaValidationException;
(function (UserLambdaValidationException) {
    UserLambdaValidationException.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(UserLambdaValidationException || (UserLambdaValidationException = {}));
export var DeliveryMediumType;
(function (DeliveryMediumType) {
    DeliveryMediumType["EMAIL"] = "EMAIL";
    DeliveryMediumType["SMS"] = "SMS";
})(DeliveryMediumType || (DeliveryMediumType = {}));
export var MessageActionType;
(function (MessageActionType) {
    MessageActionType["RESEND"] = "RESEND";
    MessageActionType["SUPPRESS"] = "SUPPRESS";
})(MessageActionType || (MessageActionType = {}));
export var AttributeType;
(function (AttributeType) {
    AttributeType.filterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.Value && { Value: SENSITIVE_STRING }))); };
})(AttributeType || (AttributeType = {}));
export var AdminCreateUserRequest;
(function (AdminCreateUserRequest) {
    AdminCreateUserRequest.filterSensitiveLog = function (obj) { return (__assign(__assign(__assign(__assign(__assign({}, obj), (obj.Username && { Username: SENSITIVE_STRING })), (obj.UserAttributes && {
        UserAttributes: obj.UserAttributes.map(function (item) { return AttributeType.filterSensitiveLog(item); }),
    })), (obj.ValidationData && {
        ValidationData: obj.ValidationData.map(function (item) { return AttributeType.filterSensitiveLog(item); }),
    })), (obj.TemporaryPassword && { TemporaryPassword: SENSITIVE_STRING }))); };
})(AdminCreateUserRequest || (AdminCreateUserRequest = {}));
export var MFAOptionType;
(function (MFAOptionType) {
    MFAOptionType.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(MFAOptionType || (MFAOptionType = {}));
export var UserStatusType;
(function (UserStatusType) {
    UserStatusType["ARCHIVED"] = "ARCHIVED";
    UserStatusType["COMPROMISED"] = "COMPROMISED";
    UserStatusType["CONFIRMED"] = "CONFIRMED";
    UserStatusType["FORCE_CHANGE_PASSWORD"] = "FORCE_CHANGE_PASSWORD";
    UserStatusType["RESET_REQUIRED"] = "RESET_REQUIRED";
    UserStatusType["UNCONFIRMED"] = "UNCONFIRMED";
    UserStatusType["UNKNOWN"] = "UNKNOWN";
})(UserStatusType || (UserStatusType = {}));
export var UserType;
(function (UserType) {
    UserType.filterSensitiveLog = function (obj) { return (__assign(__assign(__assign({}, obj), (obj.Username && { Username: SENSITIVE_STRING })), (obj.Attributes && { Attributes: obj.Attributes.map(function (item) { return AttributeType.filterSensitiveLog(item); }) }))); };
})(UserType || (UserType = {}));
export var AdminCreateUserResponse;
(function (AdminCreateUserResponse) {
    AdminCreateUserResponse.filterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.User && { User: UserType.filterSensitiveLog(obj.User) }))); };
})(AdminCreateUserResponse || (AdminCreateUserResponse = {}));
export var CodeDeliveryFailureException;
(function (CodeDeliveryFailureException) {
    CodeDeliveryFailureException.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(CodeDeliveryFailureException || (CodeDeliveryFailureException = {}));
export var InvalidPasswordException;
(function (InvalidPasswordException) {
    InvalidPasswordException.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(InvalidPasswordException || (InvalidPasswordException = {}));
export var InvalidSmsRoleAccessPolicyException;
(function (InvalidSmsRoleAccessPolicyException) {
    InvalidSmsRoleAccessPolicyException.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(InvalidSmsRoleAccessPolicyException || (InvalidSmsRoleAccessPolicyException = {}));
export var InvalidSmsRoleTrustRelationshipException;
(function (InvalidSmsRoleTrustRelationshipException) {
    InvalidSmsRoleTrustRelationshipException.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(InvalidSmsRoleTrustRelationshipException || (InvalidSmsRoleTrustRelationshipException = {}));
export var PreconditionNotMetException;
(function (PreconditionNotMetException) {
    PreconditionNotMetException.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(PreconditionNotMetException || (PreconditionNotMetException = {}));
export var UnsupportedUserStateException;
(function (UnsupportedUserStateException) {
    UnsupportedUserStateException.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(UnsupportedUserStateException || (UnsupportedUserStateException = {}));
export var UsernameExistsException;
(function (UsernameExistsException) {
    UsernameExistsException.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(UsernameExistsException || (UsernameExistsException = {}));
export var MessageTemplateType;
(function (MessageTemplateType) {
    MessageTemplateType.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(MessageTemplateType || (MessageTemplateType = {}));
export var AdminCreateUserConfigType;
(function (AdminCreateUserConfigType) {
    AdminCreateUserConfigType.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(AdminCreateUserConfigType || (AdminCreateUserConfigType = {}));
export var AdminDeleteUserRequest;
(function (AdminDeleteUserRequest) {
    AdminDeleteUserRequest.filterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.Username && { Username: SENSITIVE_STRING }))); };
})(AdminDeleteUserRequest || (AdminDeleteUserRequest = {}));
export var AdminDeleteUserAttributesRequest;
(function (AdminDeleteUserAttributesRequest) {
    AdminDeleteUserAttributesRequest.filterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.Username && { Username: SENSITIVE_STRING }))); };
})(AdminDeleteUserAttributesRequest || (AdminDeleteUserAttributesRequest = {}));
export var AdminDeleteUserAttributesResponse;
(function (AdminDeleteUserAttributesResponse) {
    AdminDeleteUserAttributesResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(AdminDeleteUserAttributesResponse || (AdminDeleteUserAttributesResponse = {}));
export var ProviderUserIdentifierType;
(function (ProviderUserIdentifierType) {
    ProviderUserIdentifierType.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(ProviderUserIdentifierType || (ProviderUserIdentifierType = {}));
export var AdminDisableProviderForUserRequest;
(function (AdminDisableProviderForUserRequest) {
    AdminDisableProviderForUserRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(AdminDisableProviderForUserRequest || (AdminDisableProviderForUserRequest = {}));
export var AdminDisableProviderForUserResponse;
(function (AdminDisableProviderForUserResponse) {
    AdminDisableProviderForUserResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(AdminDisableProviderForUserResponse || (AdminDisableProviderForUserResponse = {}));
export var AliasExistsException;
(function (AliasExistsException) {
    AliasExistsException.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(AliasExistsException || (AliasExistsException = {}));
export var AdminDisableUserRequest;
(function (AdminDisableUserRequest) {
    AdminDisableUserRequest.filterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.Username && { Username: SENSITIVE_STRING }))); };
})(AdminDisableUserRequest || (AdminDisableUserRequest = {}));
export var AdminDisableUserResponse;
(function (AdminDisableUserResponse) {
    AdminDisableUserResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(AdminDisableUserResponse || (AdminDisableUserResponse = {}));
export var AdminEnableUserRequest;
(function (AdminEnableUserRequest) {
    AdminEnableUserRequest.filterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.Username && { Username: SENSITIVE_STRING }))); };
})(AdminEnableUserRequest || (AdminEnableUserRequest = {}));
export var AdminEnableUserResponse;
(function (AdminEnableUserResponse) {
    AdminEnableUserResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(AdminEnableUserResponse || (AdminEnableUserResponse = {}));
export var AdminForgetDeviceRequest;
(function (AdminForgetDeviceRequest) {
    AdminForgetDeviceRequest.filterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.Username && { Username: SENSITIVE_STRING }))); };
})(AdminForgetDeviceRequest || (AdminForgetDeviceRequest = {}));
export var InvalidUserPoolConfigurationException;
(function (InvalidUserPoolConfigurationException) {
    InvalidUserPoolConfigurationException.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(InvalidUserPoolConfigurationException || (InvalidUserPoolConfigurationException = {}));
export var AdminGetDeviceRequest;
(function (AdminGetDeviceRequest) {
    AdminGetDeviceRequest.filterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.Username && { Username: SENSITIVE_STRING }))); };
})(AdminGetDeviceRequest || (AdminGetDeviceRequest = {}));
export var DeviceType;
(function (DeviceType) {
    DeviceType.filterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.DeviceAttributes && {
        DeviceAttributes: obj.DeviceAttributes.map(function (item) { return AttributeType.filterSensitiveLog(item); }),
    }))); };
})(DeviceType || (DeviceType = {}));
export var AdminGetDeviceResponse;
(function (AdminGetDeviceResponse) {
    AdminGetDeviceResponse.filterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.Device && { Device: DeviceType.filterSensitiveLog(obj.Device) }))); };
})(AdminGetDeviceResponse || (AdminGetDeviceResponse = {}));
export var AdminGetUserRequest;
(function (AdminGetUserRequest) {
    AdminGetUserRequest.filterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.Username && { Username: SENSITIVE_STRING }))); };
})(AdminGetUserRequest || (AdminGetUserRequest = {}));
export var AdminGetUserResponse;
(function (AdminGetUserResponse) {
    AdminGetUserResponse.filterSensitiveLog = function (obj) { return (__assign(__assign(__assign({}, obj), (obj.Username && { Username: SENSITIVE_STRING })), (obj.UserAttributes && {
        UserAttributes: obj.UserAttributes.map(function (item) { return AttributeType.filterSensitiveLog(item); }),
    }))); };
})(AdminGetUserResponse || (AdminGetUserResponse = {}));
export var AnalyticsMetadataType;
(function (AnalyticsMetadataType) {
    AnalyticsMetadataType.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(AnalyticsMetadataType || (AnalyticsMetadataType = {}));
export var AuthFlowType;
(function (AuthFlowType) {
    AuthFlowType["ADMIN_NO_SRP_AUTH"] = "ADMIN_NO_SRP_AUTH";
    AuthFlowType["ADMIN_USER_PASSWORD_AUTH"] = "ADMIN_USER_PASSWORD_AUTH";
    AuthFlowType["CUSTOM_AUTH"] = "CUSTOM_AUTH";
    AuthFlowType["REFRESH_TOKEN"] = "REFRESH_TOKEN";
    AuthFlowType["REFRESH_TOKEN_AUTH"] = "REFRESH_TOKEN_AUTH";
    AuthFlowType["USER_PASSWORD_AUTH"] = "USER_PASSWORD_AUTH";
    AuthFlowType["USER_SRP_AUTH"] = "USER_SRP_AUTH";
})(AuthFlowType || (AuthFlowType = {}));
export var HttpHeader;
(function (HttpHeader) {
    HttpHeader.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(HttpHeader || (HttpHeader = {}));
export var ContextDataType;
(function (ContextDataType) {
    ContextDataType.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(ContextDataType || (ContextDataType = {}));
export var AdminInitiateAuthRequest;
(function (AdminInitiateAuthRequest) {
    AdminInitiateAuthRequest.filterSensitiveLog = function (obj) { return (__assign(__assign(__assign({}, obj), (obj.ClientId && { ClientId: SENSITIVE_STRING })), (obj.AuthParameters && { AuthParameters: SENSITIVE_STRING }))); };
})(AdminInitiateAuthRequest || (AdminInitiateAuthRequest = {}));
export var NewDeviceMetadataType;
(function (NewDeviceMetadataType) {
    NewDeviceMetadataType.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(NewDeviceMetadataType || (NewDeviceMetadataType = {}));
export var AuthenticationResultType;
(function (AuthenticationResultType) {
    AuthenticationResultType.filterSensitiveLog = function (obj) { return (__assign(__assign(__assign(__assign({}, obj), (obj.AccessToken && { AccessToken: SENSITIVE_STRING })), (obj.RefreshToken && { RefreshToken: SENSITIVE_STRING })), (obj.IdToken && { IdToken: SENSITIVE_STRING }))); };
})(AuthenticationResultType || (AuthenticationResultType = {}));
export var ChallengeNameType;
(function (ChallengeNameType) {
    ChallengeNameType["ADMIN_NO_SRP_AUTH"] = "ADMIN_NO_SRP_AUTH";
    ChallengeNameType["CUSTOM_CHALLENGE"] = "CUSTOM_CHALLENGE";
    ChallengeNameType["DEVICE_PASSWORD_VERIFIER"] = "DEVICE_PASSWORD_VERIFIER";
    ChallengeNameType["DEVICE_SRP_AUTH"] = "DEVICE_SRP_AUTH";
    ChallengeNameType["MFA_SETUP"] = "MFA_SETUP";
    ChallengeNameType["NEW_PASSWORD_REQUIRED"] = "NEW_PASSWORD_REQUIRED";
    ChallengeNameType["PASSWORD_VERIFIER"] = "PASSWORD_VERIFIER";
    ChallengeNameType["SELECT_MFA_TYPE"] = "SELECT_MFA_TYPE";
    ChallengeNameType["SMS_MFA"] = "SMS_MFA";
    ChallengeNameType["SOFTWARE_TOKEN_MFA"] = "SOFTWARE_TOKEN_MFA";
})(ChallengeNameType || (ChallengeNameType = {}));
export var AdminInitiateAuthResponse;
(function (AdminInitiateAuthResponse) {
    AdminInitiateAuthResponse.filterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.AuthenticationResult && {
        AuthenticationResult: AuthenticationResultType.filterSensitiveLog(obj.AuthenticationResult),
    }))); };
})(AdminInitiateAuthResponse || (AdminInitiateAuthResponse = {}));
export var MFAMethodNotFoundException;
(function (MFAMethodNotFoundException) {
    MFAMethodNotFoundException.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(MFAMethodNotFoundException || (MFAMethodNotFoundException = {}));
export var PasswordResetRequiredException;
(function (PasswordResetRequiredException) {
    PasswordResetRequiredException.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(PasswordResetRequiredException || (PasswordResetRequiredException = {}));
export var UserNotConfirmedException;
(function (UserNotConfirmedException) {
    UserNotConfirmedException.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(UserNotConfirmedException || (UserNotConfirmedException = {}));
export var AdminLinkProviderForUserRequest;
(function (AdminLinkProviderForUserRequest) {
    AdminLinkProviderForUserRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(AdminLinkProviderForUserRequest || (AdminLinkProviderForUserRequest = {}));
export var AdminLinkProviderForUserResponse;
(function (AdminLinkProviderForUserResponse) {
    AdminLinkProviderForUserResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(AdminLinkProviderForUserResponse || (AdminLinkProviderForUserResponse = {}));
export var AdminListDevicesRequest;
(function (AdminListDevicesRequest) {
    AdminListDevicesRequest.filterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.Username && { Username: SENSITIVE_STRING }))); };
})(AdminListDevicesRequest || (AdminListDevicesRequest = {}));
export var AdminListDevicesResponse;
(function (AdminListDevicesResponse) {
    AdminListDevicesResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(AdminListDevicesResponse || (AdminListDevicesResponse = {}));
export var AdminListGroupsForUserRequest;
(function (AdminListGroupsForUserRequest) {
    AdminListGroupsForUserRequest.filterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.Username && { Username: SENSITIVE_STRING }))); };
})(AdminListGroupsForUserRequest || (AdminListGroupsForUserRequest = {}));
export var GroupType;
(function (GroupType) {
    GroupType.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(GroupType || (GroupType = {}));
export var AdminListGroupsForUserResponse;
(function (AdminListGroupsForUserResponse) {
    AdminListGroupsForUserResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(AdminListGroupsForUserResponse || (AdminListGroupsForUserResponse = {}));
export var AdminListUserAuthEventsRequest;
(function (AdminListUserAuthEventsRequest) {
    AdminListUserAuthEventsRequest.filterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.Username && { Username: SENSITIVE_STRING }))); };
})(AdminListUserAuthEventsRequest || (AdminListUserAuthEventsRequest = {}));
export var ChallengeName;
(function (ChallengeName) {
    ChallengeName["Mfa"] = "Mfa";
    ChallengeName["Password"] = "Password";
})(ChallengeName || (ChallengeName = {}));
export var ChallengeResponse;
(function (ChallengeResponse) {
    ChallengeResponse["Failure"] = "Failure";
    ChallengeResponse["Success"] = "Success";
})(ChallengeResponse || (ChallengeResponse = {}));
export var ChallengeResponseType;
(function (ChallengeResponseType) {
    ChallengeResponseType.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(ChallengeResponseType || (ChallengeResponseType = {}));
export var EventContextDataType;
(function (EventContextDataType) {
    EventContextDataType.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(EventContextDataType || (EventContextDataType = {}));
export var FeedbackValueType;
(function (FeedbackValueType) {
    FeedbackValueType["INVALID"] = "Invalid";
    FeedbackValueType["VALID"] = "Valid";
})(FeedbackValueType || (FeedbackValueType = {}));
export var EventFeedbackType;
(function (EventFeedbackType) {
    EventFeedbackType.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(EventFeedbackType || (EventFeedbackType = {}));
export var EventResponseType;
(function (EventResponseType) {
    EventResponseType["Failure"] = "Failure";
    EventResponseType["Success"] = "Success";
})(EventResponseType || (EventResponseType = {}));
export var RiskDecisionType;
(function (RiskDecisionType) {
    RiskDecisionType["AccountTakeover"] = "AccountTakeover";
    RiskDecisionType["Block"] = "Block";
    RiskDecisionType["NoRisk"] = "NoRisk";
})(RiskDecisionType || (RiskDecisionType = {}));
export var RiskLevelType;
(function (RiskLevelType) {
    RiskLevelType["High"] = "High";
    RiskLevelType["Low"] = "Low";
    RiskLevelType["Medium"] = "Medium";
})(RiskLevelType || (RiskLevelType = {}));
export var EventRiskType;
(function (EventRiskType) {
    EventRiskType.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(EventRiskType || (EventRiskType = {}));
export var EventType;
(function (EventType) {
    EventType["ForgotPassword"] = "ForgotPassword";
    EventType["SignIn"] = "SignIn";
    EventType["SignUp"] = "SignUp";
})(EventType || (EventType = {}));
export var AuthEventType;
(function (AuthEventType) {
    AuthEventType.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(AuthEventType || (AuthEventType = {}));
export var AdminListUserAuthEventsResponse;
(function (AdminListUserAuthEventsResponse) {
    AdminListUserAuthEventsResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(AdminListUserAuthEventsResponse || (AdminListUserAuthEventsResponse = {}));
export var UserPoolAddOnNotEnabledException;
(function (UserPoolAddOnNotEnabledException) {
    UserPoolAddOnNotEnabledException.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(UserPoolAddOnNotEnabledException || (UserPoolAddOnNotEnabledException = {}));
export var AdminRemoveUserFromGroupRequest;
(function (AdminRemoveUserFromGroupRequest) {
    AdminRemoveUserFromGroupRequest.filterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.Username && { Username: SENSITIVE_STRING }))); };
})(AdminRemoveUserFromGroupRequest || (AdminRemoveUserFromGroupRequest = {}));
export var AdminResetUserPasswordRequest;
(function (AdminResetUserPasswordRequest) {
    AdminResetUserPasswordRequest.filterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.Username && { Username: SENSITIVE_STRING }))); };
})(AdminResetUserPasswordRequest || (AdminResetUserPasswordRequest = {}));
export var AdminResetUserPasswordResponse;
(function (AdminResetUserPasswordResponse) {
    AdminResetUserPasswordResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(AdminResetUserPasswordResponse || (AdminResetUserPasswordResponse = {}));
export var InvalidEmailRoleAccessPolicyException;
(function (InvalidEmailRoleAccessPolicyException) {
    InvalidEmailRoleAccessPolicyException.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(InvalidEmailRoleAccessPolicyException || (InvalidEmailRoleAccessPolicyException = {}));
export var AdminRespondToAuthChallengeRequest;
(function (AdminRespondToAuthChallengeRequest) {
    AdminRespondToAuthChallengeRequest.filterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.ClientId && { ClientId: SENSITIVE_STRING }))); };
})(AdminRespondToAuthChallengeRequest || (AdminRespondToAuthChallengeRequest = {}));
export var AdminRespondToAuthChallengeResponse;
(function (AdminRespondToAuthChallengeResponse) {
    AdminRespondToAuthChallengeResponse.filterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.AuthenticationResult && {
        AuthenticationResult: AuthenticationResultType.filterSensitiveLog(obj.AuthenticationResult),
    }))); };
})(AdminRespondToAuthChallengeResponse || (AdminRespondToAuthChallengeResponse = {}));
export var CodeMismatchException;
(function (CodeMismatchException) {
    CodeMismatchException.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(CodeMismatchException || (CodeMismatchException = {}));
export var ExpiredCodeException;
(function (ExpiredCodeException) {
    ExpiredCodeException.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(ExpiredCodeException || (ExpiredCodeException = {}));
export var SoftwareTokenMFANotFoundException;
(function (SoftwareTokenMFANotFoundException) {
    SoftwareTokenMFANotFoundException.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(SoftwareTokenMFANotFoundException || (SoftwareTokenMFANotFoundException = {}));
export var SMSMfaSettingsType;
(function (SMSMfaSettingsType) {
    SMSMfaSettingsType.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(SMSMfaSettingsType || (SMSMfaSettingsType = {}));
export var SoftwareTokenMfaSettingsType;
(function (SoftwareTokenMfaSettingsType) {
    SoftwareTokenMfaSettingsType.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(SoftwareTokenMfaSettingsType || (SoftwareTokenMfaSettingsType = {}));
export var AdminSetUserMFAPreferenceRequest;
(function (AdminSetUserMFAPreferenceRequest) {
    AdminSetUserMFAPreferenceRequest.filterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.Username && { Username: SENSITIVE_STRING }))); };
})(AdminSetUserMFAPreferenceRequest || (AdminSetUserMFAPreferenceRequest = {}));
export var AdminSetUserMFAPreferenceResponse;
(function (AdminSetUserMFAPreferenceResponse) {
    AdminSetUserMFAPreferenceResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(AdminSetUserMFAPreferenceResponse || (AdminSetUserMFAPreferenceResponse = {}));
export var AdminSetUserPasswordRequest;
(function (AdminSetUserPasswordRequest) {
    AdminSetUserPasswordRequest.filterSensitiveLog = function (obj) { return (__assign(__assign(__assign({}, obj), (obj.Username && { Username: SENSITIVE_STRING })), (obj.Password && { Password: SENSITIVE_STRING }))); };
})(AdminSetUserPasswordRequest || (AdminSetUserPasswordRequest = {}));
export var AdminSetUserPasswordResponse;
(function (AdminSetUserPasswordResponse) {
    AdminSetUserPasswordResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(AdminSetUserPasswordResponse || (AdminSetUserPasswordResponse = {}));
export var AdminSetUserSettingsRequest;
(function (AdminSetUserSettingsRequest) {
    AdminSetUserSettingsRequest.filterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.Username && { Username: SENSITIVE_STRING }))); };
})(AdminSetUserSettingsRequest || (AdminSetUserSettingsRequest = {}));
export var AdminSetUserSettingsResponse;
(function (AdminSetUserSettingsResponse) {
    AdminSetUserSettingsResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(AdminSetUserSettingsResponse || (AdminSetUserSettingsResponse = {}));
export var AdminUpdateAuthEventFeedbackRequest;
(function (AdminUpdateAuthEventFeedbackRequest) {
    AdminUpdateAuthEventFeedbackRequest.filterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.Username && { Username: SENSITIVE_STRING }))); };
})(AdminUpdateAuthEventFeedbackRequest || (AdminUpdateAuthEventFeedbackRequest = {}));
export var AdminUpdateAuthEventFeedbackResponse;
(function (AdminUpdateAuthEventFeedbackResponse) {
    AdminUpdateAuthEventFeedbackResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(AdminUpdateAuthEventFeedbackResponse || (AdminUpdateAuthEventFeedbackResponse = {}));
export var DeviceRememberedStatusType;
(function (DeviceRememberedStatusType) {
    DeviceRememberedStatusType["NOT_REMEMBERED"] = "not_remembered";
    DeviceRememberedStatusType["REMEMBERED"] = "remembered";
})(DeviceRememberedStatusType || (DeviceRememberedStatusType = {}));
export var AdminUpdateDeviceStatusRequest;
(function (AdminUpdateDeviceStatusRequest) {
    AdminUpdateDeviceStatusRequest.filterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.Username && { Username: SENSITIVE_STRING }))); };
})(AdminUpdateDeviceStatusRequest || (AdminUpdateDeviceStatusRequest = {}));
export var AdminUpdateDeviceStatusResponse;
(function (AdminUpdateDeviceStatusResponse) {
    AdminUpdateDeviceStatusResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(AdminUpdateDeviceStatusResponse || (AdminUpdateDeviceStatusResponse = {}));
export var AdminUpdateUserAttributesRequest;
(function (AdminUpdateUserAttributesRequest) {
    AdminUpdateUserAttributesRequest.filterSensitiveLog = function (obj) { return (__assign(__assign(__assign({}, obj), (obj.Username && { Username: SENSITIVE_STRING })), (obj.UserAttributes && {
        UserAttributes: obj.UserAttributes.map(function (item) { return AttributeType.filterSensitiveLog(item); }),
    }))); };
})(AdminUpdateUserAttributesRequest || (AdminUpdateUserAttributesRequest = {}));
export var AdminUpdateUserAttributesResponse;
(function (AdminUpdateUserAttributesResponse) {
    AdminUpdateUserAttributesResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(AdminUpdateUserAttributesResponse || (AdminUpdateUserAttributesResponse = {}));
export var AdminUserGlobalSignOutRequest;
(function (AdminUserGlobalSignOutRequest) {
    AdminUserGlobalSignOutRequest.filterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.Username && { Username: SENSITIVE_STRING }))); };
})(AdminUserGlobalSignOutRequest || (AdminUserGlobalSignOutRequest = {}));
export var AdminUserGlobalSignOutResponse;
(function (AdminUserGlobalSignOutResponse) {
    AdminUserGlobalSignOutResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(AdminUserGlobalSignOutResponse || (AdminUserGlobalSignOutResponse = {}));
export var AdvancedSecurityModeType;
(function (AdvancedSecurityModeType) {
    AdvancedSecurityModeType["AUDIT"] = "AUDIT";
    AdvancedSecurityModeType["ENFORCED"] = "ENFORCED";
    AdvancedSecurityModeType["OFF"] = "OFF";
})(AdvancedSecurityModeType || (AdvancedSecurityModeType = {}));
export var AliasAttributeType;
(function (AliasAttributeType) {
    AliasAttributeType["EMAIL"] = "email";
    AliasAttributeType["PHONE_NUMBER"] = "phone_number";
    AliasAttributeType["PREFERRED_USERNAME"] = "preferred_username";
})(AliasAttributeType || (AliasAttributeType = {}));
export var AnalyticsConfigurationType;
(function (AnalyticsConfigurationType) {
    AnalyticsConfigurationType.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(AnalyticsConfigurationType || (AnalyticsConfigurationType = {}));
export var AssociateSoftwareTokenRequest;
(function (AssociateSoftwareTokenRequest) {
    AssociateSoftwareTokenRequest.filterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.AccessToken && { AccessToken: SENSITIVE_STRING }))); };
})(AssociateSoftwareTokenRequest || (AssociateSoftwareTokenRequest = {}));
export var AssociateSoftwareTokenResponse;
(function (AssociateSoftwareTokenResponse) {
    AssociateSoftwareTokenResponse.filterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.SecretCode && { SecretCode: SENSITIVE_STRING }))); };
})(AssociateSoftwareTokenResponse || (AssociateSoftwareTokenResponse = {}));
export var ConcurrentModificationException;
(function (ConcurrentModificationException) {
    ConcurrentModificationException.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(ConcurrentModificationException || (ConcurrentModificationException = {}));
export var ChangePasswordRequest;
(function (ChangePasswordRequest) {
    ChangePasswordRequest.filterSensitiveLog = function (obj) { return (__assign(__assign(__assign(__assign({}, obj), (obj.PreviousPassword && { PreviousPassword: SENSITIVE_STRING })), (obj.ProposedPassword && { ProposedPassword: SENSITIVE_STRING })), (obj.AccessToken && { AccessToken: SENSITIVE_STRING }))); };
})(ChangePasswordRequest || (ChangePasswordRequest = {}));
export var ChangePasswordResponse;
(function (ChangePasswordResponse) {
    ChangePasswordResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(ChangePasswordResponse || (ChangePasswordResponse = {}));
export var DeviceSecretVerifierConfigType;
(function (DeviceSecretVerifierConfigType) {
    DeviceSecretVerifierConfigType.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(DeviceSecretVerifierConfigType || (DeviceSecretVerifierConfigType = {}));
export var ConfirmDeviceRequest;
(function (ConfirmDeviceRequest) {
    ConfirmDeviceRequest.filterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.AccessToken && { AccessToken: SENSITIVE_STRING }))); };
})(ConfirmDeviceRequest || (ConfirmDeviceRequest = {}));
export var ConfirmDeviceResponse;
(function (ConfirmDeviceResponse) {
    ConfirmDeviceResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(ConfirmDeviceResponse || (ConfirmDeviceResponse = {}));
export var UserContextDataType;
(function (UserContextDataType) {
    UserContextDataType.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(UserContextDataType || (UserContextDataType = {}));
export var ConfirmForgotPasswordRequest;
(function (ConfirmForgotPasswordRequest) {
    ConfirmForgotPasswordRequest.filterSensitiveLog = function (obj) { return (__assign(__assign(__assign(__assign(__assign({}, obj), (obj.ClientId && { ClientId: SENSITIVE_STRING })), (obj.SecretHash && { SecretHash: SENSITIVE_STRING })), (obj.Username && { Username: SENSITIVE_STRING })), (obj.Password && { Password: SENSITIVE_STRING }))); };
})(ConfirmForgotPasswordRequest || (ConfirmForgotPasswordRequest = {}));
export var ConfirmForgotPasswordResponse;
(function (ConfirmForgotPasswordResponse) {
    ConfirmForgotPasswordResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(ConfirmForgotPasswordResponse || (ConfirmForgotPasswordResponse = {}));
export var ConfirmSignUpRequest;
(function (ConfirmSignUpRequest) {
    ConfirmSignUpRequest.filterSensitiveLog = function (obj) { return (__assign(__assign(__assign(__assign({}, obj), (obj.ClientId && { ClientId: SENSITIVE_STRING })), (obj.SecretHash && { SecretHash: SENSITIVE_STRING })), (obj.Username && { Username: SENSITIVE_STRING }))); };
})(ConfirmSignUpRequest || (ConfirmSignUpRequest = {}));
export var ConfirmSignUpResponse;
(function (ConfirmSignUpResponse) {
    ConfirmSignUpResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(ConfirmSignUpResponse || (ConfirmSignUpResponse = {}));
export var CreateGroupRequest;
(function (CreateGroupRequest) {
    CreateGroupRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(CreateGroupRequest || (CreateGroupRequest = {}));
export var CreateGroupResponse;
(function (CreateGroupResponse) {
    CreateGroupResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(CreateGroupResponse || (CreateGroupResponse = {}));
export var GroupExistsException;
(function (GroupExistsException) {
    GroupExistsException.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(GroupExistsException || (GroupExistsException = {}));
export var IdentityProviderTypeType;
(function (IdentityProviderTypeType) {
    IdentityProviderTypeType["Facebook"] = "Facebook";
    IdentityProviderTypeType["Google"] = "Google";
    IdentityProviderTypeType["LoginWithAmazon"] = "LoginWithAmazon";
    IdentityProviderTypeType["OIDC"] = "OIDC";
    IdentityProviderTypeType["SAML"] = "SAML";
    IdentityProviderTypeType["SignInWithApple"] = "SignInWithApple";
})(IdentityProviderTypeType || (IdentityProviderTypeType = {}));
export var CreateIdentityProviderRequest;
(function (CreateIdentityProviderRequest) {
    CreateIdentityProviderRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(CreateIdentityProviderRequest || (CreateIdentityProviderRequest = {}));
export var IdentityProviderType;
(function (IdentityProviderType) {
    IdentityProviderType.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(IdentityProviderType || (IdentityProviderType = {}));
export var CreateIdentityProviderResponse;
(function (CreateIdentityProviderResponse) {
    CreateIdentityProviderResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(CreateIdentityProviderResponse || (CreateIdentityProviderResponse = {}));
export var DuplicateProviderException;
(function (DuplicateProviderException) {
    DuplicateProviderException.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(DuplicateProviderException || (DuplicateProviderException = {}));
export var ResourceServerScopeType;
(function (ResourceServerScopeType) {
    ResourceServerScopeType.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(ResourceServerScopeType || (ResourceServerScopeType = {}));
export var CreateResourceServerRequest;
(function (CreateResourceServerRequest) {
    CreateResourceServerRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(CreateResourceServerRequest || (CreateResourceServerRequest = {}));
export var ResourceServerType;
(function (ResourceServerType) {
    ResourceServerType.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(ResourceServerType || (ResourceServerType = {}));
export var CreateResourceServerResponse;
(function (CreateResourceServerResponse) {
    CreateResourceServerResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(CreateResourceServerResponse || (CreateResourceServerResponse = {}));
export var CreateUserImportJobRequest;
(function (CreateUserImportJobRequest) {
    CreateUserImportJobRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(CreateUserImportJobRequest || (CreateUserImportJobRequest = {}));
export var UserImportJobStatusType;
(function (UserImportJobStatusType) {
    UserImportJobStatusType["Created"] = "Created";
    UserImportJobStatusType["Expired"] = "Expired";
    UserImportJobStatusType["Failed"] = "Failed";
    UserImportJobStatusType["InProgress"] = "InProgress";
    UserImportJobStatusType["Pending"] = "Pending";
    UserImportJobStatusType["Stopped"] = "Stopped";
    UserImportJobStatusType["Stopping"] = "Stopping";
    UserImportJobStatusType["Succeeded"] = "Succeeded";
})(UserImportJobStatusType || (UserImportJobStatusType = {}));
export var UserImportJobType;
(function (UserImportJobType) {
    UserImportJobType.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(UserImportJobType || (UserImportJobType = {}));
export var CreateUserImportJobResponse;
(function (CreateUserImportJobResponse) {
    CreateUserImportJobResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(CreateUserImportJobResponse || (CreateUserImportJobResponse = {}));
export var VerifiedAttributeType;
(function (VerifiedAttributeType) {
    VerifiedAttributeType["EMAIL"] = "email";
    VerifiedAttributeType["PHONE_NUMBER"] = "phone_number";
})(VerifiedAttributeType || (VerifiedAttributeType = {}));
export var DeviceConfigurationType;
(function (DeviceConfigurationType) {
    DeviceConfigurationType.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(DeviceConfigurationType || (DeviceConfigurationType = {}));
export var EmailSendingAccountType;
(function (EmailSendingAccountType) {
    EmailSendingAccountType["COGNITO_DEFAULT"] = "COGNITO_DEFAULT";
    EmailSendingAccountType["DEVELOPER"] = "DEVELOPER";
})(EmailSendingAccountType || (EmailSendingAccountType = {}));
export var EmailConfigurationType;
(function (EmailConfigurationType) {
    EmailConfigurationType.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(EmailConfigurationType || (EmailConfigurationType = {}));
export var CustomEmailSenderLambdaVersionType;
(function (CustomEmailSenderLambdaVersionType) {
    CustomEmailSenderLambdaVersionType["V1_0"] = "V1_0";
})(CustomEmailSenderLambdaVersionType || (CustomEmailSenderLambdaVersionType = {}));
export var CustomEmailLambdaVersionConfigType;
(function (CustomEmailLambdaVersionConfigType) {
    CustomEmailLambdaVersionConfigType.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(CustomEmailLambdaVersionConfigType || (CustomEmailLambdaVersionConfigType = {}));
export var CustomSMSSenderLambdaVersionType;
(function (CustomSMSSenderLambdaVersionType) {
    CustomSMSSenderLambdaVersionType["V1_0"] = "V1_0";
})(CustomSMSSenderLambdaVersionType || (CustomSMSSenderLambdaVersionType = {}));
export var CustomSMSLambdaVersionConfigType;
(function (CustomSMSLambdaVersionConfigType) {
    CustomSMSLambdaVersionConfigType.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(CustomSMSLambdaVersionConfigType || (CustomSMSLambdaVersionConfigType = {}));
export var LambdaConfigType;
(function (LambdaConfigType) {
    LambdaConfigType.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(LambdaConfigType || (LambdaConfigType = {}));
export var UserPoolMfaType;
(function (UserPoolMfaType) {
    UserPoolMfaType["OFF"] = "OFF";
    UserPoolMfaType["ON"] = "ON";
    UserPoolMfaType["OPTIONAL"] = "OPTIONAL";
})(UserPoolMfaType || (UserPoolMfaType = {}));
export var PasswordPolicyType;
(function (PasswordPolicyType) {
    PasswordPolicyType.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(PasswordPolicyType || (PasswordPolicyType = {}));
export var UserPoolPolicyType;
(function (UserPoolPolicyType) {
    UserPoolPolicyType.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(UserPoolPolicyType || (UserPoolPolicyType = {}));
export var SmsConfigurationType;
(function (SmsConfigurationType) {
    SmsConfigurationType.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(SmsConfigurationType || (SmsConfigurationType = {}));
export var UsernameAttributeType;
(function (UsernameAttributeType) {
    UsernameAttributeType["EMAIL"] = "email";
    UsernameAttributeType["PHONE_NUMBER"] = "phone_number";
})(UsernameAttributeType || (UsernameAttributeType = {}));
export var UsernameConfigurationType;
(function (UsernameConfigurationType) {
    UsernameConfigurationType.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(UsernameConfigurationType || (UsernameConfigurationType = {}));
export var UserPoolAddOnsType;
(function (UserPoolAddOnsType) {
    UserPoolAddOnsType.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(UserPoolAddOnsType || (UserPoolAddOnsType = {}));
export var DefaultEmailOptionType;
(function (DefaultEmailOptionType) {
    DefaultEmailOptionType["CONFIRM_WITH_CODE"] = "CONFIRM_WITH_CODE";
    DefaultEmailOptionType["CONFIRM_WITH_LINK"] = "CONFIRM_WITH_LINK";
})(DefaultEmailOptionType || (DefaultEmailOptionType = {}));
export var VerificationMessageTemplateType;
(function (VerificationMessageTemplateType) {
    VerificationMessageTemplateType.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(VerificationMessageTemplateType || (VerificationMessageTemplateType = {}));
export var CreateUserPoolRequest;
(function (CreateUserPoolRequest) {
    CreateUserPoolRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(CreateUserPoolRequest || (CreateUserPoolRequest = {}));
export var StatusType;
(function (StatusType) {
    StatusType["Disabled"] = "Disabled";
    StatusType["Enabled"] = "Enabled";
})(StatusType || (StatusType = {}));
export var UserPoolType;
(function (UserPoolType) {
    UserPoolType.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(UserPoolType || (UserPoolType = {}));
export var CreateUserPoolResponse;
(function (CreateUserPoolResponse) {
    CreateUserPoolResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(CreateUserPoolResponse || (CreateUserPoolResponse = {}));
export var UserPoolTaggingException;
(function (UserPoolTaggingException) {
    UserPoolTaggingException.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(UserPoolTaggingException || (UserPoolTaggingException = {}));
export var OAuthFlowType;
(function (OAuthFlowType) {
    OAuthFlowType["client_credentials"] = "client_credentials";
    OAuthFlowType["code"] = "code";
    OAuthFlowType["implicit"] = "implicit";
})(OAuthFlowType || (OAuthFlowType = {}));
export var ExplicitAuthFlowsType;
(function (ExplicitAuthFlowsType) {
    ExplicitAuthFlowsType["ADMIN_NO_SRP_AUTH"] = "ADMIN_NO_SRP_AUTH";
    ExplicitAuthFlowsType["ALLOW_ADMIN_USER_PASSWORD_AUTH"] = "ALLOW_ADMIN_USER_PASSWORD_AUTH";
    ExplicitAuthFlowsType["ALLOW_CUSTOM_AUTH"] = "ALLOW_CUSTOM_AUTH";
    ExplicitAuthFlowsType["ALLOW_REFRESH_TOKEN_AUTH"] = "ALLOW_REFRESH_TOKEN_AUTH";
    ExplicitAuthFlowsType["ALLOW_USER_PASSWORD_AUTH"] = "ALLOW_USER_PASSWORD_AUTH";
    ExplicitAuthFlowsType["ALLOW_USER_SRP_AUTH"] = "ALLOW_USER_SRP_AUTH";
    ExplicitAuthFlowsType["CUSTOM_AUTH_FLOW_ONLY"] = "CUSTOM_AUTH_FLOW_ONLY";
    ExplicitAuthFlowsType["USER_PASSWORD_AUTH"] = "USER_PASSWORD_AUTH";
})(ExplicitAuthFlowsType || (ExplicitAuthFlowsType = {}));
export var PreventUserExistenceErrorTypes;
(function (PreventUserExistenceErrorTypes) {
    PreventUserExistenceErrorTypes["ENABLED"] = "ENABLED";
    PreventUserExistenceErrorTypes["LEGACY"] = "LEGACY";
})(PreventUserExistenceErrorTypes || (PreventUserExistenceErrorTypes = {}));
export var TimeUnitsType;
(function (TimeUnitsType) {
    TimeUnitsType["DAYS"] = "days";
    TimeUnitsType["HOURS"] = "hours";
    TimeUnitsType["MINUTES"] = "minutes";
    TimeUnitsType["SECONDS"] = "seconds";
})(TimeUnitsType || (TimeUnitsType = {}));
export var TokenValidityUnitsType;
(function (TokenValidityUnitsType) {
    TokenValidityUnitsType.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(TokenValidityUnitsType || (TokenValidityUnitsType = {}));
export var CreateUserPoolClientRequest;
(function (CreateUserPoolClientRequest) {
    CreateUserPoolClientRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(CreateUserPoolClientRequest || (CreateUserPoolClientRequest = {}));
export var UserPoolClientType;
(function (UserPoolClientType) {
    UserPoolClientType.filterSensitiveLog = function (obj) { return (__assign(__assign(__assign({}, obj), (obj.ClientId && { ClientId: SENSITIVE_STRING })), (obj.ClientSecret && { ClientSecret: SENSITIVE_STRING }))); };
})(UserPoolClientType || (UserPoolClientType = {}));
export var CreateUserPoolClientResponse;
(function (CreateUserPoolClientResponse) {
    CreateUserPoolClientResponse.filterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.UserPoolClient && { UserPoolClient: UserPoolClientType.filterSensitiveLog(obj.UserPoolClient) }))); };
})(CreateUserPoolClientResponse || (CreateUserPoolClientResponse = {}));
export var InvalidOAuthFlowException;
(function (InvalidOAuthFlowException) {
    InvalidOAuthFlowException.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(InvalidOAuthFlowException || (InvalidOAuthFlowException = {}));
export var ScopeDoesNotExistException;
(function (ScopeDoesNotExistException) {
    ScopeDoesNotExistException.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(ScopeDoesNotExistException || (ScopeDoesNotExistException = {}));
export var CustomDomainConfigType;
(function (CustomDomainConfigType) {
    CustomDomainConfigType.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(CustomDomainConfigType || (CustomDomainConfigType = {}));
export var CreateUserPoolDomainRequest;
(function (CreateUserPoolDomainRequest) {
    CreateUserPoolDomainRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(CreateUserPoolDomainRequest || (CreateUserPoolDomainRequest = {}));
export var CreateUserPoolDomainResponse;
(function (CreateUserPoolDomainResponse) {
    CreateUserPoolDomainResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(CreateUserPoolDomainResponse || (CreateUserPoolDomainResponse = {}));
export var DeleteGroupRequest;
(function (DeleteGroupRequest) {
    DeleteGroupRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(DeleteGroupRequest || (DeleteGroupRequest = {}));
export var DeleteIdentityProviderRequest;
(function (DeleteIdentityProviderRequest) {
    DeleteIdentityProviderRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(DeleteIdentityProviderRequest || (DeleteIdentityProviderRequest = {}));
export var UnsupportedIdentityProviderException;
(function (UnsupportedIdentityProviderException) {
    UnsupportedIdentityProviderException.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(UnsupportedIdentityProviderException || (UnsupportedIdentityProviderException = {}));
export var DeleteResourceServerRequest;
(function (DeleteResourceServerRequest) {
    DeleteResourceServerRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(DeleteResourceServerRequest || (DeleteResourceServerRequest = {}));
export var DeleteUserRequest;
(function (DeleteUserRequest) {
    DeleteUserRequest.filterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.AccessToken && { AccessToken: SENSITIVE_STRING }))); };
})(DeleteUserRequest || (DeleteUserRequest = {}));
export var DeleteUserAttributesRequest;
(function (DeleteUserAttributesRequest) {
    DeleteUserAttributesRequest.filterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.AccessToken && { AccessToken: SENSITIVE_STRING }))); };
})(DeleteUserAttributesRequest || (DeleteUserAttributesRequest = {}));
export var DeleteUserAttributesResponse;
(function (DeleteUserAttributesResponse) {
    DeleteUserAttributesResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(DeleteUserAttributesResponse || (DeleteUserAttributesResponse = {}));
export var DeleteUserPoolRequest;
(function (DeleteUserPoolRequest) {
    DeleteUserPoolRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(DeleteUserPoolRequest || (DeleteUserPoolRequest = {}));
export var DeleteUserPoolClientRequest;
(function (DeleteUserPoolClientRequest) {
    DeleteUserPoolClientRequest.filterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.ClientId && { ClientId: SENSITIVE_STRING }))); };
})(DeleteUserPoolClientRequest || (DeleteUserPoolClientRequest = {}));
export var DeleteUserPoolDomainRequest;
(function (DeleteUserPoolDomainRequest) {
    DeleteUserPoolDomainRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(DeleteUserPoolDomainRequest || (DeleteUserPoolDomainRequest = {}));
export var DeleteUserPoolDomainResponse;
(function (DeleteUserPoolDomainResponse) {
    DeleteUserPoolDomainResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(DeleteUserPoolDomainResponse || (DeleteUserPoolDomainResponse = {}));
export var DescribeIdentityProviderRequest;
(function (DescribeIdentityProviderRequest) {
    DescribeIdentityProviderRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(DescribeIdentityProviderRequest || (DescribeIdentityProviderRequest = {}));
export var DescribeIdentityProviderResponse;
(function (DescribeIdentityProviderResponse) {
    DescribeIdentityProviderResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(DescribeIdentityProviderResponse || (DescribeIdentityProviderResponse = {}));
export var DescribeResourceServerRequest;
(function (DescribeResourceServerRequest) {
    DescribeResourceServerRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(DescribeResourceServerRequest || (DescribeResourceServerRequest = {}));
export var DescribeResourceServerResponse;
(function (DescribeResourceServerResponse) {
    DescribeResourceServerResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(DescribeResourceServerResponse || (DescribeResourceServerResponse = {}));
export var DescribeRiskConfigurationRequest;
(function (DescribeRiskConfigurationRequest) {
    DescribeRiskConfigurationRequest.filterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.ClientId && { ClientId: SENSITIVE_STRING }))); };
})(DescribeRiskConfigurationRequest || (DescribeRiskConfigurationRequest = {}));
export var CompromisedCredentialsEventActionType;
(function (CompromisedCredentialsEventActionType) {
    CompromisedCredentialsEventActionType["BLOCK"] = "BLOCK";
    CompromisedCredentialsEventActionType["NO_ACTION"] = "NO_ACTION";
})(CompromisedCredentialsEventActionType || (CompromisedCredentialsEventActionType = {}));
export var CompromisedCredentialsActionsType;
(function (CompromisedCredentialsActionsType) {
    CompromisedCredentialsActionsType.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(CompromisedCredentialsActionsType || (CompromisedCredentialsActionsType = {}));
export var EventFilterType;
(function (EventFilterType) {
    EventFilterType["PASSWORD_CHANGE"] = "PASSWORD_CHANGE";
    EventFilterType["SIGN_IN"] = "SIGN_IN";
    EventFilterType["SIGN_UP"] = "SIGN_UP";
})(EventFilterType || (EventFilterType = {}));
export var CompromisedCredentialsRiskConfigurationType;
(function (CompromisedCredentialsRiskConfigurationType) {
    CompromisedCredentialsRiskConfigurationType.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(CompromisedCredentialsRiskConfigurationType || (CompromisedCredentialsRiskConfigurationType = {}));
export var RiskExceptionConfigurationType;
(function (RiskExceptionConfigurationType) {
    RiskExceptionConfigurationType.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(RiskExceptionConfigurationType || (RiskExceptionConfigurationType = {}));
export var RiskConfigurationType;
(function (RiskConfigurationType) {
    RiskConfigurationType.filterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.ClientId && { ClientId: SENSITIVE_STRING }))); };
})(RiskConfigurationType || (RiskConfigurationType = {}));
export var DescribeRiskConfigurationResponse;
(function (DescribeRiskConfigurationResponse) {
    DescribeRiskConfigurationResponse.filterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.RiskConfiguration && {
        RiskConfiguration: RiskConfigurationType.filterSensitiveLog(obj.RiskConfiguration),
    }))); };
})(DescribeRiskConfigurationResponse || (DescribeRiskConfigurationResponse = {}));
export var DescribeUserImportJobRequest;
(function (DescribeUserImportJobRequest) {
    DescribeUserImportJobRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(DescribeUserImportJobRequest || (DescribeUserImportJobRequest = {}));
export var DescribeUserImportJobResponse;
(function (DescribeUserImportJobResponse) {
    DescribeUserImportJobResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(DescribeUserImportJobResponse || (DescribeUserImportJobResponse = {}));
export var DescribeUserPoolRequest;
(function (DescribeUserPoolRequest) {
    DescribeUserPoolRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(DescribeUserPoolRequest || (DescribeUserPoolRequest = {}));
export var DescribeUserPoolResponse;
(function (DescribeUserPoolResponse) {
    DescribeUserPoolResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(DescribeUserPoolResponse || (DescribeUserPoolResponse = {}));
export var DescribeUserPoolClientRequest;
(function (DescribeUserPoolClientRequest) {
    DescribeUserPoolClientRequest.filterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.ClientId && { ClientId: SENSITIVE_STRING }))); };
})(DescribeUserPoolClientRequest || (DescribeUserPoolClientRequest = {}));
export var DescribeUserPoolClientResponse;
(function (DescribeUserPoolClientResponse) {
    DescribeUserPoolClientResponse.filterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.UserPoolClient && { UserPoolClient: UserPoolClientType.filterSensitiveLog(obj.UserPoolClient) }))); };
})(DescribeUserPoolClientResponse || (DescribeUserPoolClientResponse = {}));
export var DescribeUserPoolDomainRequest;
(function (DescribeUserPoolDomainRequest) {
    DescribeUserPoolDomainRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(DescribeUserPoolDomainRequest || (DescribeUserPoolDomainRequest = {}));
export var DomainStatusType;
(function (DomainStatusType) {
    DomainStatusType["ACTIVE"] = "ACTIVE";
    DomainStatusType["CREATING"] = "CREATING";
    DomainStatusType["DELETING"] = "DELETING";
    DomainStatusType["FAILED"] = "FAILED";
    DomainStatusType["UPDATING"] = "UPDATING";
})(DomainStatusType || (DomainStatusType = {}));
export var DomainDescriptionType;
(function (DomainDescriptionType) {
    DomainDescriptionType.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(DomainDescriptionType || (DomainDescriptionType = {}));
export var DescribeUserPoolDomainResponse;
(function (DescribeUserPoolDomainResponse) {
    DescribeUserPoolDomainResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(DescribeUserPoolDomainResponse || (DescribeUserPoolDomainResponse = {}));
export var ForgetDeviceRequest;
(function (ForgetDeviceRequest) {
    ForgetDeviceRequest.filterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.AccessToken && { AccessToken: SENSITIVE_STRING }))); };
})(ForgetDeviceRequest || (ForgetDeviceRequest = {}));
export var ForgotPasswordRequest;
(function (ForgotPasswordRequest) {
    ForgotPasswordRequest.filterSensitiveLog = function (obj) { return (__assign(__assign(__assign(__assign({}, obj), (obj.ClientId && { ClientId: SENSITIVE_STRING })), (obj.SecretHash && { SecretHash: SENSITIVE_STRING })), (obj.Username && { Username: SENSITIVE_STRING }))); };
})(ForgotPasswordRequest || (ForgotPasswordRequest = {}));
export var CodeDeliveryDetailsType;
(function (CodeDeliveryDetailsType) {
    CodeDeliveryDetailsType.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(CodeDeliveryDetailsType || (CodeDeliveryDetailsType = {}));
export var ForgotPasswordResponse;
(function (ForgotPasswordResponse) {
    ForgotPasswordResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(ForgotPasswordResponse || (ForgotPasswordResponse = {}));
export var GetCSVHeaderRequest;
(function (GetCSVHeaderRequest) {
    GetCSVHeaderRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(GetCSVHeaderRequest || (GetCSVHeaderRequest = {}));
export var GetCSVHeaderResponse;
(function (GetCSVHeaderResponse) {
    GetCSVHeaderResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(GetCSVHeaderResponse || (GetCSVHeaderResponse = {}));
export var GetDeviceRequest;
(function (GetDeviceRequest) {
    GetDeviceRequest.filterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.AccessToken && { AccessToken: SENSITIVE_STRING }))); };
})(GetDeviceRequest || (GetDeviceRequest = {}));
export var GetDeviceResponse;
(function (GetDeviceResponse) {
    GetDeviceResponse.filterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.Device && { Device: DeviceType.filterSensitiveLog(obj.Device) }))); };
})(GetDeviceResponse || (GetDeviceResponse = {}));
export var GetGroupRequest;
(function (GetGroupRequest) {
    GetGroupRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(GetGroupRequest || (GetGroupRequest = {}));
export var GetGroupResponse;
(function (GetGroupResponse) {
    GetGroupResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(GetGroupResponse || (GetGroupResponse = {}));
export var GetIdentityProviderByIdentifierRequest;
(function (GetIdentityProviderByIdentifierRequest) {
    GetIdentityProviderByIdentifierRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(GetIdentityProviderByIdentifierRequest || (GetIdentityProviderByIdentifierRequest = {}));
export var GetIdentityProviderByIdentifierResponse;
(function (GetIdentityProviderByIdentifierResponse) {
    GetIdentityProviderByIdentifierResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(GetIdentityProviderByIdentifierResponse || (GetIdentityProviderByIdentifierResponse = {}));
export var GetSigningCertificateRequest;
(function (GetSigningCertificateRequest) {
    GetSigningCertificateRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(GetSigningCertificateRequest || (GetSigningCertificateRequest = {}));
export var GetSigningCertificateResponse;
(function (GetSigningCertificateResponse) {
    GetSigningCertificateResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(GetSigningCertificateResponse || (GetSigningCertificateResponse = {}));
export var GetUICustomizationRequest;
(function (GetUICustomizationRequest) {
    GetUICustomizationRequest.filterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.ClientId && { ClientId: SENSITIVE_STRING }))); };
})(GetUICustomizationRequest || (GetUICustomizationRequest = {}));
export var UICustomizationType;
(function (UICustomizationType) {
    UICustomizationType.filterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.ClientId && { ClientId: SENSITIVE_STRING }))); };
})(UICustomizationType || (UICustomizationType = {}));
export var GetUICustomizationResponse;
(function (GetUICustomizationResponse) {
    GetUICustomizationResponse.filterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.UICustomization && { UICustomization: UICustomizationType.filterSensitiveLog(obj.UICustomization) }))); };
})(GetUICustomizationResponse || (GetUICustomizationResponse = {}));
export var GetUserRequest;
(function (GetUserRequest) {
    GetUserRequest.filterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.AccessToken && { AccessToken: SENSITIVE_STRING }))); };
})(GetUserRequest || (GetUserRequest = {}));
export var GetUserResponse;
(function (GetUserResponse) {
    GetUserResponse.filterSensitiveLog = function (obj) { return (__assign(__assign(__assign({}, obj), (obj.Username && { Username: SENSITIVE_STRING })), (obj.UserAttributes && {
        UserAttributes: obj.UserAttributes.map(function (item) { return AttributeType.filterSensitiveLog(item); }),
    }))); };
})(GetUserResponse || (GetUserResponse = {}));
export var GetUserAttributeVerificationCodeRequest;
(function (GetUserAttributeVerificationCodeRequest) {
    GetUserAttributeVerificationCodeRequest.filterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.AccessToken && { AccessToken: SENSITIVE_STRING }))); };
})(GetUserAttributeVerificationCodeRequest || (GetUserAttributeVerificationCodeRequest = {}));
export var GetUserAttributeVerificationCodeResponse;
(function (GetUserAttributeVerificationCodeResponse) {
    GetUserAttributeVerificationCodeResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(GetUserAttributeVerificationCodeResponse || (GetUserAttributeVerificationCodeResponse = {}));
export var GetUserPoolMfaConfigRequest;
(function (GetUserPoolMfaConfigRequest) {
    GetUserPoolMfaConfigRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(GetUserPoolMfaConfigRequest || (GetUserPoolMfaConfigRequest = {}));
export var SmsMfaConfigType;
(function (SmsMfaConfigType) {
    SmsMfaConfigType.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(SmsMfaConfigType || (SmsMfaConfigType = {}));
export var SoftwareTokenMfaConfigType;
(function (SoftwareTokenMfaConfigType) {
    SoftwareTokenMfaConfigType.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(SoftwareTokenMfaConfigType || (SoftwareTokenMfaConfigType = {}));
export var GetUserPoolMfaConfigResponse;
(function (GetUserPoolMfaConfigResponse) {
    GetUserPoolMfaConfigResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(GetUserPoolMfaConfigResponse || (GetUserPoolMfaConfigResponse = {}));
export var GlobalSignOutRequest;
(function (GlobalSignOutRequest) {
    GlobalSignOutRequest.filterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.AccessToken && { AccessToken: SENSITIVE_STRING }))); };
})(GlobalSignOutRequest || (GlobalSignOutRequest = {}));
export var GlobalSignOutResponse;
(function (GlobalSignOutResponse) {
    GlobalSignOutResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(GlobalSignOutResponse || (GlobalSignOutResponse = {}));
export var InitiateAuthRequest;
(function (InitiateAuthRequest) {
    InitiateAuthRequest.filterSensitiveLog = function (obj) { return (__assign(__assign(__assign({}, obj), (obj.AuthParameters && { AuthParameters: SENSITIVE_STRING })), (obj.ClientId && { ClientId: SENSITIVE_STRING }))); };
})(InitiateAuthRequest || (InitiateAuthRequest = {}));
export var InitiateAuthResponse;
(function (InitiateAuthResponse) {
    InitiateAuthResponse.filterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.AuthenticationResult && {
        AuthenticationResult: AuthenticationResultType.filterSensitiveLog(obj.AuthenticationResult),
    }))); };
})(InitiateAuthResponse || (InitiateAuthResponse = {}));
export var ListDevicesRequest;
(function (ListDevicesRequest) {
    ListDevicesRequest.filterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.AccessToken && { AccessToken: SENSITIVE_STRING }))); };
})(ListDevicesRequest || (ListDevicesRequest = {}));
export var ListDevicesResponse;
(function (ListDevicesResponse) {
    ListDevicesResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(ListDevicesResponse || (ListDevicesResponse = {}));
export var ListGroupsRequest;
(function (ListGroupsRequest) {
    ListGroupsRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(ListGroupsRequest || (ListGroupsRequest = {}));
export var ListGroupsResponse;
(function (ListGroupsResponse) {
    ListGroupsResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(ListGroupsResponse || (ListGroupsResponse = {}));
export var ListIdentityProvidersRequest;
(function (ListIdentityProvidersRequest) {
    ListIdentityProvidersRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(ListIdentityProvidersRequest || (ListIdentityProvidersRequest = {}));
export var ProviderDescription;
(function (ProviderDescription) {
    ProviderDescription.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(ProviderDescription || (ProviderDescription = {}));
export var ListIdentityProvidersResponse;
(function (ListIdentityProvidersResponse) {
    ListIdentityProvidersResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(ListIdentityProvidersResponse || (ListIdentityProvidersResponse = {}));
export var ListResourceServersRequest;
(function (ListResourceServersRequest) {
    ListResourceServersRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(ListResourceServersRequest || (ListResourceServersRequest = {}));
export var ListResourceServersResponse;
(function (ListResourceServersResponse) {
    ListResourceServersResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(ListResourceServersResponse || (ListResourceServersResponse = {}));
export var ListTagsForResourceRequest;
(function (ListTagsForResourceRequest) {
    ListTagsForResourceRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(ListTagsForResourceRequest || (ListTagsForResourceRequest = {}));
export var ListTagsForResourceResponse;
(function (ListTagsForResourceResponse) {
    ListTagsForResourceResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(ListTagsForResourceResponse || (ListTagsForResourceResponse = {}));
export var ListUserImportJobsRequest;
(function (ListUserImportJobsRequest) {
    ListUserImportJobsRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(ListUserImportJobsRequest || (ListUserImportJobsRequest = {}));
export var ListUserImportJobsResponse;
(function (ListUserImportJobsResponse) {
    ListUserImportJobsResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(ListUserImportJobsResponse || (ListUserImportJobsResponse = {}));
export var ListUserPoolClientsRequest;
(function (ListUserPoolClientsRequest) {
    ListUserPoolClientsRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(ListUserPoolClientsRequest || (ListUserPoolClientsRequest = {}));
export var UserPoolClientDescription;
(function (UserPoolClientDescription) {
    UserPoolClientDescription.filterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.ClientId && { ClientId: SENSITIVE_STRING }))); };
})(UserPoolClientDescription || (UserPoolClientDescription = {}));
export var ListUserPoolClientsResponse;
(function (ListUserPoolClientsResponse) {
    ListUserPoolClientsResponse.filterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.UserPoolClients && {
        UserPoolClients: obj.UserPoolClients.map(function (item) { return UserPoolClientDescription.filterSensitiveLog(item); }),
    }))); };
})(ListUserPoolClientsResponse || (ListUserPoolClientsResponse = {}));
export var ListUserPoolsRequest;
(function (ListUserPoolsRequest) {
    ListUserPoolsRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(ListUserPoolsRequest || (ListUserPoolsRequest = {}));
export var UserPoolDescriptionType;
(function (UserPoolDescriptionType) {
    UserPoolDescriptionType.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(UserPoolDescriptionType || (UserPoolDescriptionType = {}));
export var ListUserPoolsResponse;
(function (ListUserPoolsResponse) {
    ListUserPoolsResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(ListUserPoolsResponse || (ListUserPoolsResponse = {}));
export var ListUsersRequest;
(function (ListUsersRequest) {
    ListUsersRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(ListUsersRequest || (ListUsersRequest = {}));
export var ListUsersResponse;
(function (ListUsersResponse) {
    ListUsersResponse.filterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.Users && { Users: obj.Users.map(function (item) { return UserType.filterSensitiveLog(item); }) }))); };
})(ListUsersResponse || (ListUsersResponse = {}));
export var ListUsersInGroupRequest;
(function (ListUsersInGroupRequest) {
    ListUsersInGroupRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(ListUsersInGroupRequest || (ListUsersInGroupRequest = {}));
export var ListUsersInGroupResponse;
(function (ListUsersInGroupResponse) {
    ListUsersInGroupResponse.filterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.Users && { Users: obj.Users.map(function (item) { return UserType.filterSensitiveLog(item); }) }))); };
})(ListUsersInGroupResponse || (ListUsersInGroupResponse = {}));
export var ResendConfirmationCodeRequest;
(function (ResendConfirmationCodeRequest) {
    ResendConfirmationCodeRequest.filterSensitiveLog = function (obj) { return (__assign(__assign(__assign(__assign({}, obj), (obj.ClientId && { ClientId: SENSITIVE_STRING })), (obj.SecretHash && { SecretHash: SENSITIVE_STRING })), (obj.Username && { Username: SENSITIVE_STRING }))); };
})(ResendConfirmationCodeRequest || (ResendConfirmationCodeRequest = {}));
export var ResendConfirmationCodeResponse;
(function (ResendConfirmationCodeResponse) {
    ResendConfirmationCodeResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(ResendConfirmationCodeResponse || (ResendConfirmationCodeResponse = {}));
export var RespondToAuthChallengeRequest;
(function (RespondToAuthChallengeRequest) {
    RespondToAuthChallengeRequest.filterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.ClientId && { ClientId: SENSITIVE_STRING }))); };
})(RespondToAuthChallengeRequest || (RespondToAuthChallengeRequest = {}));
export var RespondToAuthChallengeResponse;
(function (RespondToAuthChallengeResponse) {
    RespondToAuthChallengeResponse.filterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.AuthenticationResult && {
        AuthenticationResult: AuthenticationResultType.filterSensitiveLog(obj.AuthenticationResult),
    }))); };
})(RespondToAuthChallengeResponse || (RespondToAuthChallengeResponse = {}));
export var RevokeTokenRequest;
(function (RevokeTokenRequest) {
    RevokeTokenRequest.filterSensitiveLog = function (obj) { return (__assign(__assign(__assign(__assign({}, obj), (obj.Token && { Token: SENSITIVE_STRING })), (obj.ClientId && { ClientId: SENSITIVE_STRING })), (obj.ClientSecret && { ClientSecret: SENSITIVE_STRING }))); };
})(RevokeTokenRequest || (RevokeTokenRequest = {}));
export var RevokeTokenResponse;
(function (RevokeTokenResponse) {
    RevokeTokenResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(RevokeTokenResponse || (RevokeTokenResponse = {}));
export var UnauthorizedException;
(function (UnauthorizedException) {
    UnauthorizedException.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(UnauthorizedException || (UnauthorizedException = {}));
export var UnsupportedOperationException;
(function (UnsupportedOperationException) {
    UnsupportedOperationException.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(UnsupportedOperationException || (UnsupportedOperationException = {}));
export var UnsupportedTokenTypeException;
(function (UnsupportedTokenTypeException) {
    UnsupportedTokenTypeException.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(UnsupportedTokenTypeException || (UnsupportedTokenTypeException = {}));
export var SetRiskConfigurationRequest;
(function (SetRiskConfigurationRequest) {
    SetRiskConfigurationRequest.filterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.ClientId && { ClientId: SENSITIVE_STRING }))); };
})(SetRiskConfigurationRequest || (SetRiskConfigurationRequest = {}));
export var SetRiskConfigurationResponse;
(function (SetRiskConfigurationResponse) {
    SetRiskConfigurationResponse.filterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.RiskConfiguration && {
        RiskConfiguration: RiskConfigurationType.filterSensitiveLog(obj.RiskConfiguration),
    }))); };
})(SetRiskConfigurationResponse || (SetRiskConfigurationResponse = {}));
export var SetUICustomizationRequest;
(function (SetUICustomizationRequest) {
    SetUICustomizationRequest.filterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.ClientId && { ClientId: SENSITIVE_STRING }))); };
})(SetUICustomizationRequest || (SetUICustomizationRequest = {}));
export var SetUICustomizationResponse;
(function (SetUICustomizationResponse) {
    SetUICustomizationResponse.filterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.UICustomization && { UICustomization: UICustomizationType.filterSensitiveLog(obj.UICustomization) }))); };
})(SetUICustomizationResponse || (SetUICustomizationResponse = {}));
export var SetUserMFAPreferenceRequest;
(function (SetUserMFAPreferenceRequest) {
    SetUserMFAPreferenceRequest.filterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.AccessToken && { AccessToken: SENSITIVE_STRING }))); };
})(SetUserMFAPreferenceRequest || (SetUserMFAPreferenceRequest = {}));
export var SetUserMFAPreferenceResponse;
(function (SetUserMFAPreferenceResponse) {
    SetUserMFAPreferenceResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(SetUserMFAPreferenceResponse || (SetUserMFAPreferenceResponse = {}));
export var SetUserPoolMfaConfigRequest;
(function (SetUserPoolMfaConfigRequest) {
    SetUserPoolMfaConfigRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(SetUserPoolMfaConfigRequest || (SetUserPoolMfaConfigRequest = {}));
export var SetUserPoolMfaConfigResponse;
(function (SetUserPoolMfaConfigResponse) {
    SetUserPoolMfaConfigResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(SetUserPoolMfaConfigResponse || (SetUserPoolMfaConfigResponse = {}));
export var SetUserSettingsRequest;
(function (SetUserSettingsRequest) {
    SetUserSettingsRequest.filterSensitiveLog = function (obj) { return (__assign(__assign({}, obj), (obj.AccessToken && { AccessToken: SENSITIVE_STRING }))); };
})(SetUserSettingsRequest || (SetUserSettingsRequest = {}));
export var SetUserSettingsResponse;
(function (SetUserSettingsResponse) {
    SetUserSettingsResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(SetUserSettingsResponse || (SetUserSettingsResponse = {}));
export var SignUpRequest;
(function (SignUpRequest) {
    SignUpRequest.filterSensitiveLog = function (obj) { return (__assign(__assign(__assign(__assign(__assign(__assign(__assign({}, obj), (obj.ClientId && { ClientId: SENSITIVE_STRING })), (obj.SecretHash && { SecretHash: SENSITIVE_STRING })), (obj.Username && { Username: SENSITIVE_STRING })), (obj.Password && { Password: SENSITIVE_STRING })), (obj.UserAttributes && {
        UserAttributes: obj.UserAttributes.map(function (item) { return AttributeType.filterSensitiveLog(item); }),
    })), (obj.ValidationData && {
        ValidationData: obj.ValidationData.map(function (item) { return AttributeType.filterSensitiveLog(item); }),
    }))); };
})(SignUpRequest || (SignUpRequest = {}));
export var SignUpResponse;
(function (SignUpResponse) {
    SignUpResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(SignUpResponse || (SignUpResponse = {}));
export var StartUserImportJobRequest;
(function (StartUserImportJobRequest) {
    StartUserImportJobRequest.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(StartUserImportJobRequest || (StartUserImportJobRequest = {}));
